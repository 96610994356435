<template>
  <div class="container mt-5   px-md-5" style="margin-left:auto; margin-right:auto;">
    <div class=" mb-4">
        <h2 class="text-left" style="text-transform: capitalize;">{{title}}</h2>
    </div>



  <div class="container mt-5   px-md-5" style="margin-left:auto; margin-right:auto;">
      <form @submit.prevent="saveDataConfig()">
          <div class="row">
            <div class="col-6 mb-3">
              <label for="">texto banner</label> 
              <input required class="form-control" v-model="con.blog_banner1_texto" type="text" name="" id="">
            </div>
            <div class="col-6 mb-3">
              <label for="">img banner</label> <br>
              <input type="file" id="fileblog" ref="fileblog" v-on:change="onFileSelected('blog',10)"/><br>   
              <input  class="form-control" v-model="con.blog_banner1_img" type="text" name="" id="">        
            </div>
          </div>

        <div class="row my-4">
          <div class="col-12">
            <b-button type="submit" class="btn-primary mx-1 float-right ml-3">Guardar</b-button>
            <!-- <b-button @click="$refs['modal-item2'].hide()" class="btn-white-outline-small float-right">Cancelar</b-button> -->
          </div>
        </div>
      </form>
  </div>

    <div class="container-fluid p-0">
      <button @click="showModal(1)" class="btn-primary"><i class="fa fa-plus mr-2"></i>Nuevo articulo</button>
    </div>
    <div class="my-4 mb-2">
      <!-- <h2 class="text-left">Mis solicitudes</h2> -->
    </div>
       
    <div class="bg-gray ">
      <b-table 
          hover
          responsive 
          :items="table_list" 
          :fields="fields" 
          :current-page="currentPage" 
          :per-page="perPage" 
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
      >
      <template #cell(name)="row">
          {{ row.value.first }} {{ row.value.last }}
      </template>
      <template #cell(descripcion_tarjeta)="data">
          <div v-html="data.item.descripcion_tarjeta"></div>

        <!-- <vue-editor class="form-control p-0 m-0" style="height:150px;" v-model="data.item.descripcion_tarjeta" :editorOptions="editorOptions"/> -->
      </template>

      <template #cell(action)="data">
          <!-- <button @click="$router.push('NAO_Detalles/'+data.item.id)" class="btn-white-outline-small mx-1"><i class="fa fa-edit"></i></button> -->
          <b-button @click="sendMail(data.item)" v-b-tooltip.hover title="Enviar notificiación de nueva publicación" class="btn-white-outline-small mx-1"><i class="fa fa-check"></i></b-button>
          <button @click="showModal(2, data.item)" class="btn-white-outline-small mx-1"><i class="fa fa-pen"></i></button>
          <button @click="deleteItem(data.item)" class="btn-primary-small mx-1"><i class="fa fa-trash"></i></button>
      </template>
      <template #row-details="row">
          <b-card>
          <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
          </b-card>
      </template>
      </b-table>
        <div>
          
      </div>
    </div>
    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" hide-goto-end-buttons hide-ellipsis prev-text="Prev" next-text="Next" align="right"></b-pagination>

  
  <!-- editar producto -->
  <b-modal ref="modal-item2" size="lg" hide-footer >
    <template #modal-title>
      {{modalText}}
    </template>
    <form @submit.prevent="saveData()" style="padding:10px">
    <b-tabs  content-class="mt-3">
      <b-tab active title="Articulo">
        <template>
            <!-- <b-tabs content-class="mt-3">
              <b-tab title="General"> -->
                <div class="row mb-3">
                  <div class="col-12">
                    <label for="">Titulo</label>
                    <input class="form-control" v-model="row.titulo_tarjeta" type="text" name="" id="">
                  </div>
                  
                </div>
                <div class="row">
                  <!-- <div class="col-9">
                    <label for="">Nombre</label>
                    <input required class="form-control" v-model="row.titulo" type="text" name="" id="">
                  </div> -->
                    <div class="col-12">
                        <label for="">Descripción</label>                
                        <vue-editor class="form-control p-0 m-0" style="height:150px;" v-model="row.descripcion_tarjeta" :editorOptions="editorOptions"/>
                    </div>
                    
                </div>
              <div class="col-3">
                <b-form-checkbox class="mt-4" size="lg" id="checkbox-2" v-model="row.destacado" name="checkbox-2" value="1" unchecked-value="0" > 
                    <span for="observaciones" class="h6">Artículo destacado</span>
                </b-form-checkbox>
              </div>               
              <div class="row mt-1">
                <div class="col-12">
                  <label for="">Imagen Tarjeta</label><br>
                  <input type="file" id="file1" ref="file1" v-on:change="onFileSelected('blog',1)"/><br>
                  <input class="form-control" v-model="row.imagen_tarjeta" type="text" name="" id="">
                </div>
              </div>
        </template>
      </b-tab>
      <b-tab title="Detalle">
        <template>
            <!-- <b-tabs content-class="mt-3">
              <b-tab title="General"> -->
                <div class="row mb-3">
                  <div class="col-12">
                    <label for="">Titulo</label>
                    <input class="form-control" v-model="row.titulo_detalle" type="text" name="" id="">
                  </div>
                  <!-- <div class="col-5">
                    <label for="">Texto del boton</label>
                    <input class="form-control" v-model="row.texto_boton_detalle" type="text" name="" id="">
                  </div> -->
                </div>
                <div class="row">
                  <!-- <div class="col-9">
                    <label for="">Nombre</label>
                    <input required class="form-control" v-model="row.titulo" type="text" name="" id="">
                  </div> -->
                    <div class="col-12">
                        <label for="">Descripción</label>                
                        <vue-editor  id="editor" class="form-control p-0  m-0" style="height:100%;min-height:200px;" v-model="row.descripcion_detalle"
                        use-custom-image-handler 
                        @image-added="handleImageAdded" />
                    </div>
                    
                </div>
               
                <div class="row mt-5">
                  <div class="col-6">
                    <label for="">Texto del botón</label>
                    <input class="form-control mt-4" v-model="row.texto_boton_detalle" type="text" name="" id="">
                  </div>
                  <div class="col-6">
                    <label for="">Ruta de el botón</label><br>
                    <input type="file" id="file9" ref="file9" v-on:change="onFileSelected('blog',9)"/><br>
                    <input class="form-control" v-model="row.ruta_boton_detalle" type="text" name="" id="">
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-12">
                    <label for="">Imagen Detalle</label><br>
                    <input type="file" id="file2" ref="file2" v-on:change="onFileSelected('cruceros',2)"/><br>
                    <input class="form-control" v-model="row.imagen_detalle" type="text" name="" id="">
                  </div>
                </div> -->
                

        </template>
      </b-tab>
    </b-tabs>
      <div class="row mt-4">
        <div class="col-12">
          <b-button type="submit" class="btn-primary-small mx-1 float-right ml-3">Guardar</b-button>
          <b-button @click="$refs['modal-item2'].hide()" class="btn-white-outline-small float-right">Cancelar</b-button>
        </div>
      </div>
    </form>
  </b-modal>  
  </div>
</template>

<script>
// import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
import { VueEditor, Quill } from "vue2-editor";

let Image = Quill.import('formats/image');
Image.className = 'imgblog';
Quill.register(Image, true);

// import FormVue from "@/components/Form.vue";
export default {
  components: {
    // FormVue,
    VueEditor
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      editorOptions : {
        modules: {
          toolbar: false,
        },
      },
      selected: null,
        categorias: [
          { id: 1, text: 'Categoría' },
          { id: 2, text: 'This is First option' },
          { id: 3, text: 'Selected Option' },
          { id: 4, text: 'This is an option with object value' },
          { id: 5, text: 'This one is disabled'}
        ],
        subcategorias: [
        ],
        Producto: [
          { id: 1, text: 'Producto' },
          { id: 2, text: 'This is First option' },
          { id: 3, text: 'Selected Option' },
          { id: 4, text: 'This is an option with object value' },
          { id: 5, text: 'This one is disabled'}
        ],
      perPage: 25,
      currentPage: 1,

      table: "blog",
      modalText: 'Nueva Ficha',
      title: 'Blog',
      items: [],

      table_list: [
        // { id: 1 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 2 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 3 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 4 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 5 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
      ],
      row:{},
      categoria_id: 6,
      fields: [
          { key: 'id', label: 'id', sortable: true, sortDirection: 'desc', class: 'text-center' },
          { key: 'titulo_tarjeta', label: 'Título', sortable: true, class: 'text-center' },
          { key: 'descripcion_tarjeta', label: 'descripción', sortable: true, class: 'text-center' },
          // { key: 'fecha_inicio', label: 'fecha inicio', sortable: true, class: 'text-center' },
          // { key: 'fecha_fin', label: 'fecha fin', sortable: true, class: 'text-center' },
          // { key: 'estado', label: 'Estado', sortable: true, class: 'text-center' },
          { key: 'action', label: 'Acciones',class: 'text-center'},
        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      file1: null,
      filename1: null,
      file2: null,
      filename2: null,
      file3: null,
      filename3: null,
      file4: null,
      filename4: null,
      file5: null,
      filename5: null,
      file6: null,
      filename6: null,
      file9: null,
      filename9: null,
      fileblog: null,
      filenameblog: null,
      con:{blog_banner1_texto:'',blog_banner1_img:''}
    
    };
  },
  async mounted() {
    this.$isLoading(true)
    await this.getData();
    await this.getDataBlog();
    // await this.getSubcategories();
    this.$isLoading(false)
  },
  methods:{
    async sendMail(row) {
      console.log('save and sendmail', row)
      try {
        let req
        req = await this.$store.dispatch("post", {
          path: 'enviarCorreo/blog/',
          data: {id: row.id }
        });
        
        console.log(req)
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Correos enviados correctamente!",
        });
        this.getData();
      } catch (error) {
        console.log(error)
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error, revisar datos.",
        });        
        
      }
    },       
    async saveDataConfig(){
      try {
        let req
        let uploadImg
        this.$isLoading(true)        
        if(this.filenameblog!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filenameblog ,file: this.fileblog})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.con.blog_banner1_img = 'https://naotravelco.com/img/' + this.filenameblog
          }
        }
      
        let path = 'configuracion/modify/1'
        //console.log('path', path)
        req = await this.$store.dispatch("put", {
          path: path,
          data: this.con
        });
      
        
        this.$isLoading(false)
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Datos guardados correctamente!",
        });
        // this.$refs['modal-item2'].hide()
        this.getData();
      } catch (error) {
        this.$isLoading(false)
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error, revisar datos.",
        });        
        
      }
    },
    async handleImageAdded(file, Editor, cursorLocation) {
      console.log('init handle')
      //let filename = 
      let extension = file.name.split('.')[1];
      let filename6 = Math.floor(Math.random() * 9999999999999 + 1);
      filename6 = 'blog' + '_' + filename6 +'.'+ extension;      
      let uploadImg = await this.$store.dispatch("onFileSelected", {filename: filename6 ,file: file})
      if(uploadImg == true){
        console.log('----',uploadImg)
        let url = 'https://naotravelco.com/img/' + filename6
        Editor.insertEmbed(cursorLocation, 'image', url);
      }



      // const CLIENT_ID = '993793b1d8d3e2e'
      // var formData = new FormData()
      // formData.append('image', file)
      // axios({
      //   url: 'https://api.imgur.com/3/image',
      //   method: 'POST',
      //   headers:{
      //     'Authorization': 'Client-ID ' + CLIENT_ID
      //   },
      //   data: formData
      // })
      // .then((result) => {
      //   console.log(result);
      //   let url = result.data.data.link
      //   Editor.insertEmbed(cursorLocation, 'image', url);
      // })
      // .catch((err) => {
      //   console.log(err);
      // })
    },
    onFileSelected(type, item){
      if(item==1){
        this.file1 = event.target.files[0];
        this.filename1 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename1 = type + '_' + this.filename1+ '.jpg';
      }
      if(item==2){
        this.file2 = event.target.files[0];
        let extension = this.file2.name.split('.')[1];
        this.filename2 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename2 = type + '_' + this.filename2 +'.'+ extension;
      }
      if(item==3){
        this.file3 = event.target.files[0];
        let extension = this.file3.name.split('.')[1];
        this.filename3 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename3 = type + '_' + this.filename3 +'.'+ extension;
      }
      if(item==4){
        this.file4 = event.target.files[0];
        let extension = this.file4.name.split('.')[1];
        this.filename4 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename4 = type + '_' + this.filename4 +'.'+ extension;
      }
      if(item==5){
        this.file5 = event.target.files[0];
        let extension = this.file5.name.split('.')[1];
        this.filename5 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename5 = type + '_' + this.filename5 +'.'+ extension;
      }
      if(item==6){
        this.file6 = event.target.files[0];
        let extension = this.file6.name.split('.')[1];
        this.filename6 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename6 = type + '_' + this.filename6 +'.'+ extension;
      }
      if(item==9){
        this.file9 = event.target.files[0];
        let extension = this.file9.name.split('.')[1];
        this.filename9 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename9 = type + '_' + this.filename9 +'.'+ extension;
      }
      if(item==10){
        this.fileblog = event.target.files[0];
        let extension = this.fileblog.name.split('.')[1];
        this.filenameblog = Math.floor(Math.random() * 9999999999999 + 1);
        this.filenameblog = type + '_' + this.filenameblog +'.'+ extension;
      }
    },  
    showModal(type, item){
      console.log(item)
      if(type == 2){
        this.modalText = 'Editar Ficha'
        this.row = item
      }
      else{
        this.row = {}
        this.modalText = 'Nueva Ficha'
      }
      this.$refs['modal-item2'].show()
    },
    async getSubcategories() {
      try {
        let res = await this.$store.dispatch("get", { path: 'subcategorias/getAllByCategory/' + this.categoria_id});
        console.log('------------->',res)
        if(res.length>0){
          this.subcategorias = res
        }
        else{
          this.subcategorias = []
        }
      } catch (error) {
        this.subcategorias = []
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error:" + error,
        });
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("get", { path: this.table + '/getAll'});
        //console.log(res)
        if(res.length>0){
          this.table_list = res
          this.totalRows = this.table_list.length
        }
        else{
          this.table_list = []
          this.totalRows = this.table_list.length
        }
      } catch (error) {
        this.table_list = []
        this.totalRows = this.table_list.length
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },
    async getDataBlog() {
      try {
        let res = await this.$store.dispatch("get", { path: 'configuracion/getAll'});
        if(res.length>0){
          console.log('conf',res[0])
          this.con.blog_banner1_img = res[0].blog_banner1_img
          this.con.blog_banner1_texto = res[0].blog_banner1_texto
        }
        else{
          this.con = {}
        }
      } catch (error) {
        this.con = {}
      }
    },
    async saveData() {
      try {
        let req
        let uploadImg
        this.$isLoading(true)        
        if(this.filename1!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename1 ,file: this.file1})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.imagen_tarjeta = 'https://naotravelco.com/img/' + this.filename1
          }
        }
        if(this.filename2!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename2 ,file: this.file2})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.imagen_detalle = 'https://naotravelco.com/img/' + this.filename2
          }
        }
        if(this.filename3!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename3 ,file: this.file3})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.detalle_urlimagen = 'https://naotravelco.com/img/' + this.filename3
          }
        }
        if(this.filename4!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename4 ,file: this.file4})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.detalle_banner1_urlimagen = 'https://naotravelco.com/img/' + this.filename4
          }
        }
        if(this.filename5!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename5 ,file: this.file5})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.detalle_banner2_urlimagen = 'https://naotravelco.com/img/' + this.filename5
          }
        }
        if(this.filename6!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename6 ,file: this.file6})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.documento_descargable_tar = 'https://naotravelco.com/img/' + this.filename6
          }
        }
        if(this.filename9!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename9 ,file: this.file9})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.ruta_boton_detalle = 'https://naotravelco.com/img/' + this.filename9
          }
        }
        if(this.row.id){
          let path = 'blog/modify/'+ this.row.id
          //console.log('path', path)
          req = await this.$store.dispatch("put", {
            path: path,
            data: this.row
          });
        }
        else{
          this.row.fecha = this.$moment().utc().format('YYYY-MM-DD')
          req = await this.$store.dispatch("post", {
            path: this.table + '/new',
            data: this.row
          });
        }
        this.$isLoading(false)
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Datos guardados correctamente!",
        });
        this.$refs['modal-item2'].hide()
        this.getData();
      } catch (error) {
        this.$isLoading(false)
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error, revisar datos.",
        });        
        
      }
    },
    ///////////////
    gotoTanks(){
      console.log('voy a ...')
      this.$router.push('/admin/gracias_solicitud')
    },

    async deleteItem(item) {
      try {
        console.log(item.id)
        const sure = await confirm(
          "¿Está seguro que desea eliminar este elemento?"
        );
        if (!sure) {
          return;
        }

        const req = await this.$store.dispatch("delete", {
          path: this.table +'/' + item.id,
        });
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Registro eliminado correctamente!",
        });        
        this.getData();
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Opps!",
          text: "Error: " + error,
        });        
        console.log(error)
        
      }
    },
  },
};
</script>

<style>
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css'; 

</style>